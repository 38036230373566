














































import { SfImage } from '@storefront-ui/vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import CustomButton from '~/components/General/CustomButton.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import CustomCarousel from '../General/CustomCarousel.vue';
import CustomCarouselItem from '../General/CustomCarouselItem.vue';

export default defineComponent({
  name: 'FeaturedDesigners',
  components: { SfImage, CustomCarousel, CustomCarouselItem, CustomButton },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { locale } = useI18n();
    const featuredDesignersContent = ref();
    const featuredDesignersHeaderContent = ref();

    const rawData = (locale === 'it' ? props.data?.it : props.data) || {};
    featuredDesignersHeaderContent.value = {
      'link-label': props.data
        ? // eslint-disable-next-line no-prototype-builtins
          props.data.hasOwnProperty('it') &&
          props.data?.it?.active &&
          locale === 'it'
          ? props.data?.it?.['header-link-label']
          : props.data['header-link-label']
        : '',
      title: props.data
        ? // eslint-disable-next-line no-prototype-builtins
          props.data.hasOwnProperty('it') &&
          props.data?.it?.active &&
          locale === 'it'
          ? props.data.it.title
          : props.data.title
        : '',
      cards: props.data
        ? // eslint-disable-next-line no-prototype-builtins
          props.data.hasOwnProperty('it') &&
          props.data?.it?.active &&
          locale === 'it'
          ? props.data.it.cards
          : props.data.cards
        : '',
    };
    featuredDesignersContent.value =
      rawData.cards?.filter((item) => item !== null && item.active) || [];

    return {
      featuredDesignersHeaderContent,
      featuredDesignersContent,
    };
  },
  data() {
    return {
      featuredDesignersHeader: {
        linkTo: '/designers',
      },
      carouselSettings: {
        perView: 4,
        breakpoints: {
          1023: {
            perView: 2,
          },
          767: {
            perView: 1,
            peek: {
              before: 0,
              after: 70,
            },
          },
        },
      },
    };
  },
});
